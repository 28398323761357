import actions from './actions';

const {
  GET_TASK_TRACKING_BEGIN,
  GET_TASK_TRACKING_SUCCESS,
  GET_TASK_TRACKING_ERROR,
  POST_TASK_TRACKING_BEGIN,
  POST_TASK_TRACKING_SUCCESS,
  POST_TASK_TRACKING_ERROR,
  UPDATE_TASK_TRACKING_BEGIN,
  UPDATE_TASK_TRACKING_SUCCESS,
  UPDATE_TASK_TRACKING_ERROR,
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  PATCH_USER_STATUS_BEGIN,
  PATCH_USER_STATUS_SUCCESS,
  PATCH_USER_STATUS_ERROR,
  PATCH_RESET_PASSWORD_BEGIN,
  PATCH_RESET_PASSWORD_SUCCESS,
  PATCH_RESET_PASSWORD_ERROR,
  GET_USERS_BEGIN,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_TASK_TRACKING_MOVE_BEGIN,
  GET_TASK_TRACKING_MOVE_SUCCESS,
  GET_TASK_TRACKING_MOVE_ERROR,
  POST_TASK_TRACKING_MOVE_BEGIN,
  POST_TASK_TRACKING_MOVE_SUCCESS,
  POST_TASK_TRACKING_MOVE_ERROR,
  GET_PUBLIC_HOLIDAYS_BEGIN,
  GET_PUBLIC_HOLIDAYS_SUCCESS,
  GET_PUBLIC_HOLIDAYS_ERROR,
  ADD_OR_UPDATE_PUBLIC_HOLIDAYS_BEGIN,
  ADD_OR_UPDATE_PUBLIC_HOLIDAYS_SUCCESS,
  ADD_OR_UPDATE_PUBLIC_HOLIDAYS_ERROR,
  DELETE_PUBLIC_HOLIDAYS_BEGIN,
  DELETE_PUBLIC_HOLIDAYS_SUCCESS,
  DELETE_PUBLIC_HOLIDAYS_ERROR,
  RESET_ERROR_MESSAGE,
  GET_BANK_DEFINITIONS_BEGIN,
  GET_BANK_DEFINITIONS_ERROR,
  GET_BANK_DEFINITIONS_SUCCESS,
  UPDATE_BANK_DEFINITIONS_BEGIN,
  UPDATE_BANK_DEFINITIONS_ERROR,
  UPDATE_BANK_DEFINITIONS_SUCCESS,
  SAYAC_GET_BEGIN,
  SAYAC_GET_SUCCESS,
  SAYAC_GET_ERROR,
  GET_FIRMA_USERS_BEGIN,
  GET_FIRMA_USERS_SUCCESS,
  GET_FIRMA_USERS_ERROR,
  GET_JOB_TRACKING_LIST_BY_USER_BEGIN,
  GET_JOB_TRACKING_LIST_BY_USER_SUCCESS,
  GET_JOB_TRACKING_LIST_BY_USER_ERROR,
  GET_MASRAF_ETIKET_BEGIN,
  GET_MASRAF_ETIKET_SUCCESS,
  GET_MASRAF_ETIKET_ERROR,
  GET_MENU_ALL_BEGIN,
  GET_MENU_ALL_SUCCESS,
  GET_MENU_ALL_ERROR,
  GET_MENU_USER_BEGIN,
  GET_MENU_USER_SUCCESS,
  GET_MENU_USER_ERROR,
  GET_FIRMALAR_BEGIN,
  GET_FIRMALAR_SUCCESS,
  GET_FIRMALAR_ERROR,
} = actions;

const initState = {
  loading: false,
  error: null,
  taskTrackingList: [],
  taskTrackingMove: [],
  userList: [],
  publicHolidays: [],
  banks: [],
  sayac: [],
  firmaUserList: [],
  jobTrackingListByUserList: [],
  masrafEtiket: [],
  menuTree: [],
  userMenu: [],
  firmalar: [],
};

const commonReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_TASK_TRACKING_BEGIN:
    case POST_TASK_TRACKING_BEGIN:
    case UPDATE_TASK_TRACKING_BEGIN:
    case CREATE_USER_BEGIN:
    case PATCH_USER_STATUS_BEGIN:
    case PATCH_RESET_PASSWORD_BEGIN:
    case GET_USERS_BEGIN:
    case GET_TASK_TRACKING_MOVE_BEGIN:
    case GET_BANK_DEFINITIONS_BEGIN:
    case UPDATE_BANK_DEFINITIONS_BEGIN:
    case POST_TASK_TRACKING_MOVE_BEGIN:
    case GET_PUBLIC_HOLIDAYS_BEGIN:
    case DELETE_PUBLIC_HOLIDAYS_BEGIN:
    case ADD_OR_UPDATE_PUBLIC_HOLIDAYS_BEGIN:
    case SAYAC_GET_BEGIN:
    case GET_JOB_TRACKING_LIST_BY_USER_BEGIN:
    case GET_FIRMA_USERS_BEGIN:
    case GET_MASRAF_ETIKET_BEGIN:
    case GET_MENU_ALL_BEGIN:
    case GET_MENU_USER_BEGIN:
    case GET_FIRMALAR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TASK_TRACKING_SUCCESS:
      return {
        ...state,
        loading: false,
        taskTrackingList: data,
      };
    case POST_TASK_TRACKING_SUCCESS:
      state.taskTrackingList.push(data);
      return {
        ...state,
        loading: false,
        taskTrackingList: state.taskTrackingList,
      };
    case UPDATE_TASK_TRACKING_SUCCESS:
      const taskIndex = state.taskTrackingList.findIndex(c => c.id === data.id);
      state.taskTrackingList[taskIndex] = data;
      return {
        ...state,
        loading: false,
        taskTrackingList: state.taskTrackingList,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: data,
      };

    case GET_BANK_DEFINITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: data,
      };
    case GET_MENU_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        menuTree: data,
      };
    case GET_MENU_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userMenu: data,
      };
    case GET_FIRMALAR_SUCCESS:
      return {
        ...state,
        loading: false,
        firmalar: data,
      };
    case CREATE_USER_SUCCESS:
      state.userList.push(data);
      return {
        ...state,
        loading: false,
        userList: state.userList,
      };
    case SAYAC_GET_SUCCESS:
      state.sayac.push(data);
      return {
        ...state,
        loading: false,
        sayac: state.sayac,
      };
    case PATCH_USER_STATUS_SUCCESS:
    case UPDATE_BANK_DEFINITIONS_SUCCESS:
    case PATCH_RESET_PASSWORD_SUCCESS:
      const userIndex = state.userList.findIndex(user => user.id === data.id);
      state.userList[userIndex] = data;
      return {
        ...state,
        loading: false,
        userList: state.userList,
      };
    case GET_TASK_TRACKING_MOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        taskTrackingMove: data,
      };
    case POST_TASK_TRACKING_MOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        taskTrackingMove: [data, ...state.taskTrackingMove],
      };
    case GET_PUBLIC_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        publicHolidays: data,
      };
    case ADD_OR_UPDATE_PUBLIC_HOLIDAYS_SUCCESS: {
      const index = state.publicHolidays.findIndex(
        holiday => holiday.id === data.id
      );
      if (index > -1) {
        state.publicHolidays[index] = data;
        return {
          ...state,
          loading: false,
          publicHolidays: state.publicHolidays,
        };
      } else {
        const newPublicHolidays = state.publicHolidays;
        newPublicHolidays.push(data);
        return {
          ...state,
          loading: false,
          publicHolidays: newPublicHolidays,
        };
      }
    }
    case DELETE_PUBLIC_HOLIDAYS_SUCCESS: {
      const index = state.publicHolidays.findIndex(
        holiday => holiday.id === data.id
      );
      state.publicHolidays.splice(index, 1);
      return {
        ...state,
        loading: false,
        publicHolidays: state.publicHolidays,
      };
    }
    case GET_FIRMA_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        firmaUserList: data,
      };
    case GET_JOB_TRACKING_LIST_BY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        jobTrackingListByUserList: data,
      };

    case GET_MASRAF_ETIKET_SUCCESS:
      return {
        ...state,
        loading: false,
        masrafEtiket: data,
      };
    case GET_TASK_TRACKING_ERROR:
    case POST_TASK_TRACKING_ERROR:
    case UPDATE_TASK_TRACKING_ERROR:
    case CREATE_USER_ERROR:
    case PATCH_USER_STATUS_ERROR:
    case PATCH_RESET_PASSWORD_ERROR:
    case GET_USERS_ERROR:
    case GET_TASK_TRACKING_MOVE_ERROR:
    case POST_TASK_TRACKING_MOVE_ERROR:
    case DELETE_PUBLIC_HOLIDAYS_ERROR:
    case ADD_OR_UPDATE_PUBLIC_HOLIDAYS_ERROR:
    case SAYAC_GET_ERROR:
    case GET_FIRMA_USERS_ERROR:
    case GET_MASRAF_ETIKET_ERROR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_PUBLIC_HOLIDAYS_ERROR:
    case RESET_ERROR_MESSAGE:
      return {
        ...state,
        error: null,
      };
    case GET_BANK_DEFINITIONS_ERROR:
      return {
        ...state,
        banks: [],
        error: err,
      };
    case GET_MENU_ALL_ERROR:
      return {
        ...state,
        error: err,
        menuTree: [],
      };
    case GET_MENU_USER_ERROR:
      return {
        ...state,
        error: err,
        userMenu: [],
      };
    case GET_FIRMALAR_ERROR:
      return {
        ...state,
        error: err,
        firmalar: [],
      };

    case UPDATE_BANK_DEFINITIONS_ERROR:
    case GET_JOB_TRACKING_LIST_BY_USER_ERROR:
    default:
      return state;
  }
};

export { commonReducer };
