import { DataService } from '../../config/dataService/dataService';
import { Endpoints } from '../../config/Endpoints';
import reduxStore from '../../redux/store';
import actions from './actions';

const {
  getTaskTrackingBegin,
  getTaskTrackingSuccess,
  getTaskTrackingError,
  postTaskTrackingBegin,
  postTaskTrackingSuccess,
  postTaskTrackingError,
  updateTaskTrackingBegin,
  updateTaskTrackingSuccess,
  updateTaskTrackingError,
  createUserBegin,
  createUserSuccess,
  createUserError,
  patchUserStatusBegin,
  patchUserStatusSuccess,
  patchUserStatusError,
  patchResetPasswordBegin,
  patchResetPasswordSuccess,
  patchResetPasswordError,
  getUsersBegin,
  getUsersSuccess,
  getUsersError,
  getTaskTrackingMoveBegin,
  getTaskTrackingMoveSuccess,
  getTaskTrackingMoveError,
  postTaskTrackingMoveBegin,
  postTaskTrackingMoveSuccess,
  postTaskTrackingMoveError,
  getPublicHolidaysBegin,
  getPublicHolidaysSuccess,
  getPublicHolidaysError,
  addOrUpdatePublicHolidaysBegin,
  addOrUpdatePublicHolidaysSuccess,
  addOrUpdatePublicHolidaysError,
  deletePublicHolidaysBegin,
  deletePublicHolidaysSuccess,
  deletePublicHolidaysError,
  resetErrorMessage,
  getBankDefinitionsBegin,
  getBankDefinitionsError,
  getBankDefinitionsSuccess,
  updateBankDefinitionsBegin,
  updateBankDefinitionsError,
  updateBankDefinitionsSuccess,
  sayacGetBegin,
  sayacGetSuccess,
  sayacGetError,
  getFirmaUsersBegin,
  getFirmaUsersSuccess,
  getFirmaUsersError,
  getJobTrackingListByUserBegin,
  getJobTrackingListByUserSuccess,
  getJobTrackingListByUserError,
  getMasrafEtiketBegin,
  getMasrafEtiketSuccess,
  getMasrafEtiketError,
  updateMasrafTipKeysBegin,
  updateMasrafTipKeysSuccess,
  updateMasrafTipKeysError,
  addMasrafTipKeysBegin,
  addMasrafTipKeysSuccess,
  addMasrafTipKeysError,
  updateUserPasswordBegin,
  updateUserPasswordSuccess,
  updateUserPasswordError,
  getMenuAllBegin,
  getMenuAllSuccess,
  getMenuAllError,
  getMenuUserBegin,
  getMenuUserSuccess,
  getMenuUserError,
  updateMenuUserBegin,
  updateMenuUserSuccess,
  updateMenuUserError,
  getFirmalarBegin,
  getFirmalarSuccess,
  getFirmalarError,
} = actions;

const getTaskTracking = () => {
  return async dispatch => {
    const globalState = reduxStore.getState();
    if (globalState.common.taskTrackingList.length === 0) {
      dispatch(getTaskTrackingBegin());
      const response = await DataService.get(Endpoints.GetTaskTracking);
      if (response.status === 200) {
        dispatch(getTaskTrackingSuccess(response.data));
      } else {
        dispatch(getTaskTrackingError(response));
      }
    }
  };
};

const postTaskTracking = data => {
  return async dispatch => {
    dispatch(postTaskTrackingBegin());
    const response = await DataService.post(Endpoints.PostTaskTracking, data);
    if (response.status === 200) {
      dispatch(postTaskTrackingSuccess(response.data));
    } else {
      dispatch(postTaskTrackingError(response));
    }
  };
};

const updateTaskTracking = data => {
  return async dispatch => {
    dispatch(updateTaskTrackingBegin());
    const response = await DataService.put(Endpoints.UpdateTaskTracking, data);
    if (response.status === 200) {
      dispatch(updateTaskTrackingSuccess(response.data));
    } else {
      dispatch(updateTaskTrackingError(response));
    }
  };
};

const createUser = data => {
  return async dispatch => {
    dispatch(createUserBegin());
    const response = await DataService.post(Endpoints.Register, data);
    if (response.status === 200) {
      dispatch(createUserSuccess(response.data));
    } else {
      dispatch(createUserError(response));
    }
    return response;
  };
};

const patchUserStatus = userId => {
  return async dispatch => {
    dispatch(patchUserStatusBegin());
    const response = await DataService.patch(
      `${Endpoints.UserSetStatus}/${userId}`
    );
    if (response.status === 200) {
      dispatch(patchUserStatusSuccess(response.data));
    } else {
      dispatch(patchUserStatusError(response));
    }
    return response;
  };
};

const patchResetPassword = (userId, data) => {
  return async dispatch => {
    dispatch(patchResetPasswordBegin());
    const response = await DataService.patch(
      `${Endpoints.ResetPassword}/${userId}`,
      data
    );
    if (response.status === 200) {
      dispatch(patchResetPasswordSuccess(response.data));
    } else {
      dispatch(patchResetPasswordError(response));
    }
    return response;
  };
};

const getUsers = () => {
  return async dispatch => {
    dispatch(getUsersBegin());
    const response = await DataService.get(Endpoints.GetUsers);
    if (response.status === 200) {
      dispatch(getUsersSuccess(response.data));
    } else {
      dispatch(getUsersError(response));
    }
  };
};

const getMenuAll = () => {
  return async dispatch => {
    dispatch(getMenuAllBegin());
    const response = await DataService.get(Endpoints.GetMenuAll);
    if (response.status === 200) {
      dispatch(getMenuAllSuccess(response.data));
    } else {
      dispatch(getMenuAllError(response));
    }
  };
};

const getMenuUser = param => {
  return async dispatch => {
    dispatch(getMenuUserBegin());
    const response = await DataService.get(Endpoints.GetUserMenu + `${param}`);
    if (response.status === 200) {
      dispatch(getMenuUserSuccess(response.data));
    } else {
      dispatch(getMenuUserError(response));
    }
    return response;
  };
};

const updateMenuUser = data => {
  return async dispatch => {
    dispatch(updateMenuUserBegin());
    const response = await DataService.post(Endpoints.UpdateMenuUser, data);
    if (response.status === 200) {
      dispatch(updateMenuUserSuccess(response.data));
    } else {
      dispatch(updateMenuUserError(response.data));
    }
    return response;
  };
};

const getFirmalar = () => {
  return async dispatch => {
    dispatch(getFirmalarBegin());
    const response = await DataService.get(Endpoints.GetFirmalar);
    if (response.status === 200) {
      dispatch(getFirmalarSuccess(response.data));
    } else {
      dispatch(getFirmalarError(response));
    }
  };
};

const getFirmaUsers = () => {
  return async dispatch => {
    dispatch(getFirmaUsersBegin());
    const response = await DataService.get(Endpoints.GetFirmaUsers);
    if (response.status === 200) {
      dispatch(getFirmaUsersSuccess(response.data));
    } else {
      dispatch(getFirmaUsersError(response));
    }
  };
};

// const getFirmaIdByUserId = () => {
//   return async dispatch => {
//     dispatch(getFirmaIdByUserIdBegin());
//     const response = await DataService.get(Endpoints.GetFirmaIdByUserId);
//     if (response.status === 200) {
//       dispatch(getFirmaIdByUserIdSuccess(response.data));
//     } else {
//       dispatch(getFirmaIdByUserIdError(response));
//     }
//   };
// };

const getTaskTrackingMove = params => {
  return async dispatch => {
    dispatch(getTaskTrackingMoveBegin());
    const response = await DataService.get(
      Endpoints.GetTaskTrackingMove,
      params
    );
    if (response.status === 200) {
      dispatch(getTaskTrackingMoveSuccess(response.data));
    } else {
      dispatch(getTaskTrackingMoveError(response));
    }
    return response;
  };
};

const postTaskTrackingMove = data => {
  return async dispatch => {
    dispatch(postTaskTrackingMoveBegin());
    const response = await DataService.post(
      Endpoints.PostTaskTrackingMove,
      data
    );
    if (response.status === 200) {
      dispatch(postTaskTrackingMoveSuccess(response.data));
    } else {
      dispatch(postTaskTrackingMoveError(response));
    }
    return response;
  };
};

const getPublicHolidays = () => {
  return async dispatch => {
    dispatch(getPublicHolidaysBegin());
    const response = await DataService.get(Endpoints.GetPublicHolidays);
    if (response.status === 200) {
      dispatch(getPublicHolidaysSuccess(response.data));
    } else {
      dispatch(getPublicHolidaysError(response));
    }
    return response;
  };
};

const addOrUpdatePublicHolidays = data => {
  return async dispatch => {
    dispatch(addOrUpdatePublicHolidaysBegin());
    const response = await DataService.post(
      Endpoints.AddOrUpdatePublicHolidays,
      data
    );
    if (response.status === 200) {
      dispatch(addOrUpdatePublicHolidaysSuccess(response.data));
    } else {
      dispatch(addOrUpdatePublicHolidaysError(response));
    }
    return response;
  };
};

const deletePublicHolidays = id => {
  return async dispatch => {
    dispatch(deletePublicHolidaysBegin());
    const response = await DataService.delete(
      `${Endpoints.DeletePublicHolidays}/${id}`
    );
    if (response.status === 200) {
      dispatch(deletePublicHolidaysSuccess(response.data));
    } else {
      dispatch(deletePublicHolidaysError(response));
    }
    return response;
  };
};

const commonResetError = () => {
  return dispatch => {
    dispatch(resetErrorMessage());
  };
};

const getBankDefinitions = param => {
  return async dispatch => {
    dispatch(getBankDefinitionsBegin());
    const response = await DataService.get(Endpoints.getBankDefinitions, param);
    if (response.status === 200) {
      dispatch(getBankDefinitionsSuccess(response.data));
    } else {
      dispatch(getBankDefinitionsError(response));
    }
    return response;
  };
};

const updateBankDefinitions = data => {
  return async dispatch => {
    dispatch(updateBankDefinitionsBegin());
    const response = await DataService.put(Endpoints.getBankDefinitions, data);
    if (response.status === 200) {
      dispatch(updateBankDefinitionsSuccess(response.data));
    } else {
      dispatch(updateBankDefinitionsError(response));
    }
    return response;
  };
};

const getSayac = id => {
  return async dispatch => {
    dispatch(sayacGetBegin());
    const response = await DataService.get(`${Endpoints.SayacGetir}/${id}`);
    if (response.status === 200) {
      dispatch(sayacGetSuccess(response.data));
    } else {
      dispatch(sayacGetError(response));
    }
    return response;
  };
};

const getJobTrackingListByUser = () => {
  return async dispatch => {
    dispatch(getJobTrackingListByUserBegin());
    const response = await DataService.get(Endpoints.GetJobTrackingListByUser);
    if (response.status === 200) {
      dispatch(getJobTrackingListByUserSuccess(response.data));
    } else {
      dispatch(getJobTrackingListByUserError(response.data));
    }
    return response;
  };
};

const getMasrafEtiket = () => {
  return async dispatch => {
    dispatch(getMasrafEtiketBegin());
    const response = await DataService.get(Endpoints.GetMasrafEtiket);
    if (response.status === 200) {
      dispatch(getMasrafEtiketSuccess(response.data));
    } else {
      dispatch(getMasrafEtiketError(response));
    }
    return response;
  };
};

const updateMasrafTipKeys = data => {
  return async dispatch => {
    dispatch(updateMasrafTipKeysBegin());
    const response = await DataService.put(Endpoints.GetMasrafEtiket, data);
    if (response.status === 200) {
      dispatch(updateMasrafTipKeysSuccess(response.data));
    } else {
      dispatch(updateMasrafTipKeysError(response));
    }
    return response;
  };
};

const addMasrafTipKeys = data => {
  return async dispatch => {
    dispatch(addMasrafTipKeysBegin());
    const response = await DataService.post(Endpoints.AddMasrafEtiket, data);
    if (response.status === 200) {
      dispatch(addMasrafTipKeysSuccess(response.data));
    } else {
      dispatch(addMasrafTipKeysError(response));
    }
    return response;
  };
};

const updateUserPassword = data => {
  return async dispatch => {
    dispatch(updateUserPasswordBegin());
    const response = await DataService.post(Endpoints.UpdateUserPassword, data);
    if (response.status === 200) {
      dispatch(updateUserPasswordSuccess(response.data));
    } else {
      dispatch(updateUserPasswordError(response.data));
    }
    return response;
  };
};

export {
  getTaskTracking,
  postTaskTracking,
  updateTaskTracking,
  createUser,
  patchUserStatus,
  patchResetPassword,
  getUsers,
  getFirmaUsers,
  getTaskTrackingMove,
  postTaskTrackingMove,
  getPublicHolidays,
  addOrUpdatePublicHolidays,
  deletePublicHolidays,
  commonResetError,
  getBankDefinitions,
  updateBankDefinitions,
  getSayac,
  getJobTrackingListByUser,
  getMasrafEtiket,
  updateMasrafTipKeys,
  addMasrafTipKeys,
  updateUserPassword,
  getMenuAll,
  getMenuUser,
  updateMenuUser,
  getFirmalar,
};
